import { Link } from "react-router-dom";
import { getDocs, collection, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../../services/firebaseConfig";
import { useEffect, useState } from "react";
import moment from "moment";

const PostsList = () => {
    const [posts, setPosts] = useState([]);
    const postCollectionRef = collection(db, "posts");

    useEffect(() => {
        const getPosts = async () => {
            const data = await getDocs(postCollectionRef);
            setPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getPosts();
    }, []);

    const deletePost = async (id) => {
        const postDoc = doc(db, "posts", id);
        await deleteDoc(postDoc);
    };

    return (
        <>
            <h1 className="display-5">Lista de Posts</h1>
            <Link to={"/admin/posts/registrar"}>
                <button type="button" className="btn btn-primary">
                    Add Post
                </button>
            </Link>

            <table className="table mt-5 table-striped">
                <thead>
                    <tr>
                        <th scope="col">Titulo</th>
                        <th scope="col">Publicado em</th>
                        <th scope="col">Categoria</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {posts.map((post) => {
                        const publicationDate = post.publicacao?.toDate();
                        const formattedDate = publicationDate
                            ? moment(publicationDate).format("DD/MM/YYYY")
                            : "N/A";

                        return (
                            <tr>
                                <td>{post.titulo}</td>
                                <td>{formattedDate}</td>
                                <td>{post.categoria}</td>
                                <td>{post.status}</td>
                                <td>
                                    <div className="d-flex gap-3">
                                        <button className="btn btn-warning">
                                            Editar
                                        </button>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => deletePost(post.id)}
                                        >
                                            Deletar
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
export default PostsList;
