import { Link, Outlet } from "react-router-dom";
import AdminSideBar from "../../components/AdminSideBar";
import Sidebar from "../../components/SideBar";
import Layout from "../../components/Layout";

const Admin = () => {
    return (
        <Layout>
            <Outlet />
        </Layout>
    );
};
export default Admin;
