import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { GlobalContext } from "./context/globalContext";
import ModalLogout from "./components/ModalLogout";
import ScrollToTop from "./components/ScrollToTop";

const App: React.FC = () => {
    const {
        state: { showLogoutModal },
    } = useContext(GlobalContext);

    return (
        <>
            <ModalLogout isOpen={showLogoutModal} />
            <ScrollToTop />
            <Outlet />
        </>
    );
};

export default App;
