import styled, { css } from "styled-components";

interface SessionProps {
    background: string;
    backgroundimage: string;
}

export const Session = styled.div<SessionProps>`
    ${(props) => css`
        background: ${props.background};
        background-image: url(${props.backgroundimage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        padding-top: 3%;
        padding-bottom: 3%;
    `}
`;
