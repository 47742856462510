import React, {
    createContext,
    useState,
    useEffect,
    ReactNode,
    Dispatch,
    SetStateAction,
} from "react";

interface GlobalState {
    posts: any[];
    user: any[];
    products: any[];
    productDetail: any[];
    destakDetail: any[];
    imagesGAlery: any[];
    currentUser: any | null;
    showLogoutModal: boolean;
    isAuthenticated: boolean;
}

const initialState: GlobalState = {
    posts: [],
    user: [],
    products: [],
    productDetail: [],
    destakDetail: [],
    imagesGAlery: [],
    currentUser: null,
    showLogoutModal: false,
    isAuthenticated: false,
};

interface GlobalContextType {
    state: GlobalState;
    login: (user: any, token: string) => void;
    logout: () => void;
}

const GlobalContext = createContext<GlobalContextType>({
    state: initialState,
    login: () => {},
    logout: () => {},
});

// Define o provedor do contexto
const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<GlobalState>(() => {
        // Tenta recuperar o usuário e token do sessionStorage
        const storedUser = sessionStorage.getItem("currentUser");
        const storedToken = sessionStorage.getItem("authToken");

        let currentUser = null;
        try {
            if (storedUser) {
                currentUser = JSON.parse(storedUser);
            }
        } catch (error) {
            console.error(
                "Erro ao parsear o usuário do sessionStorage:",
                error,
            );
        }

        const isAuthenticated = currentUser && storedToken;

        // Retorna o estado com todas as propriedades de GlobalState, garantindo que não faltem dados
        return {
            posts: [],
            user: [],
            products: [],
            productDetail: [],
            destakDetail: [],
            imagesGAlery: [],
            currentUser: currentUser,
            showLogoutModal: false,
            isAuthenticated: isAuthenticated,
        };
    });

    const login = (user: any, token: string) => {
        // Armazenar o token e o usuário no sessionStorage
        sessionStorage.setItem("authToken", token);
        sessionStorage.setItem("currentUser", JSON.stringify(user));

        setState({
            posts: [],
            user: [],
            products: [],
            productDetail: [],
            destakDetail: [],
            imagesGAlery: [],
            currentUser: user,
            showLogoutModal: false,
            isAuthenticated: true,
        });
    };

    const logout = () => {
        // Remover os dados do sessionStorage
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("currentUser");

        setState({
            posts: [],
            user: [],
            products: [],
            productDetail: [],
            destakDetail: [],
            imagesGAlery: [],
            currentUser: null,
            showLogoutModal: false,
            isAuthenticated: false,
        });
    };

    return (
        <GlobalContext.Provider value={{ state, login, logout }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalProvider };
