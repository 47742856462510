import React from "react";
import Sidebar from "../SideBar";
import { Content, LayoutContainer } from "./Layout.style";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <LayoutContainer>
            <Sidebar />
            <Content>{children}</Content>
        </LayoutContainer>
    );
};

export default Layout;
