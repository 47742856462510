import styled from "styled-components";
import { theme } from "../../theme/theme";

export const SidebarContainer = styled.div<{ isVisible: boolean }>`
    background-color: ${theme.lightBlue};
    width: 250px;
    height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-right: 2px solid ${theme.mediumBlue};
    transition: transform 0.3s ease;

    @media (max-width: 768px) {
        width: 80px;
    }

    @media (max-width: 576px) {
        transform: ${({ isVisible }) => (isVisible ? "translateX(0)" : "translateX(-100%)")};
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }
`;

export const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const LogoutWrapper = styled.div`
    margin-top: auto;
`;
