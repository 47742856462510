import React, { useContext } from "react";
import SidebarItem from "./SidebarItem";
import SidebarHeader from "./SidebarHeader";
import {
    ItemsWrapper,
    SidebarContainer,
    LogoutWrapper,
} from "./Sidebar.styles";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext";

const Sidebar: React.FC = () => {
    const { logout } = useContext(GlobalContext);
    const location = useLocation();
    const items = [
        { icon: "bi-house", label: "Home", path: "/" },
        { icon: "bi-person", label: "Usuários", path: "/admin/usuarios" },
        { icon: "bi-newspaper", label: "Posts", path: "/admin/posts" },
        {
            icon: "bi-pc-display-horizontal",
            label: "Produtos",
            path: "/admin/produtos",
        },
        { icon: "bi-image", label: "Galerias", path: "/admin/galerias" },
        {
            icon: "bi-chat-right-dots",
            label: "Mensagens",
            path: "/admin/mensagens",
        },
    ];

    const logoutItem = {
        icon: "bi-box-arrow-right",
        label: "Logout",
    };

    return (
        <SidebarContainer isVisible={true}>
            <SidebarHeader title="My App" />
            <ItemsWrapper>
                {items.map((item) => (
                    <SidebarItem
                        key={item.path}
                        icon={item.icon}
                        label={item.label}
                        path={item.path}
                        active={location.pathname === item.path}
                    />
                ))}
            </ItemsWrapper>
            <LogoutWrapper>
                <SidebarItem
                    key={logoutItem.label}
                    icon={logoutItem.icon}
                    label={logoutItem.label}
                    isLogout={true}
                    onClick={() => logout()}
                    path={""}
                />
            </LogoutWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;
