import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "../../../theme/theme";

export const ItemWrapper = styled(Link) <{ active?: boolean; isLogout?: boolean }>`
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: ${({ isLogout, active }) =>
        isLogout ? theme.red : active ? theme.mediumBlue : "transparent"};
    color: ${({ isLogout, active }) =>
        isLogout ? theme.white : active ? theme.white : theme.darkBlue};
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: ${({ isLogout }) => (isLogout ? theme.darkRed : theme.lightBlue)};
    }

    @media (max-width: 768px) {
        justify-content: center;
        padding: 0.5rem;
    }
`;


export const Icon = styled.i`
    font-size: 1.2rem;
    margin-right: 0.5rem;

    @media (max-width: 768px) {
        margin-right: 0;
    }
`;

export const Label = styled.span`
    font-size: 1rem;

    @media (max-width: 768px) {
        display: none;
    }
`;
