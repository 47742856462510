import React, { useContext } from "react";
import { theme } from "../../../theme/theme";
import { GlobalContext } from "../../../context/globalContext";

interface SidebarHeaderProps {
    title: string;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ title }) => {
    const { state, login, logout } = useContext(GlobalContext);
    return (
        <div
            className="p-3 text-center fw-bold"
            style={{
                backgroundColor: theme.darkBlue,
                color: theme.white,
                borderRadius: "8px",
            }}
        >
            {state.currentUser.firstname}
        </div>
    );
};

export default SidebarHeader;
