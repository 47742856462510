import React from "react";
import { Icon, ItemWrapper, Label } from "./SidebarItem.styles";


interface SidebarItemProps {
    icon: string;
    label: string;
    path: string;
    active?: boolean;
    isLogout?: boolean;
    onClick?: () => void;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, label, path, active, onClick }) => {
    return (
        <ItemWrapper to={path} active={active} onClick={onClick}>
            <Icon className={`bi ${icon}`} />
            <Label>{label}</Label>
        </ItemWrapper>
    );
};

export default SidebarItem;
