import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

export const Form = styled.form`
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
`;

export const Input = styled.input`
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;

    &:focus {
        outline: none;
        border-color: #007bff;
    }
`;

export const Button = styled.button`
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
    &:disabled {
        background-color: #b0b0b0;
        cursor: not-allowed;}
`;

export const ErrorMessage = styled.p`
    color: red;
    font-size: 0.875rem;
    margin-bottom: 1rem;
`;
