import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GlobalContext } from "../../context/globalContext";
import { Session } from "../../components/Session";
import bgBlue from "../../_assets/bg-blue.png";
import {
    Container,
    Form,
    Title,
    Input,
    Button,
    ErrorMessage,
} from "./Login.styles";
import api from "../../services/api";

interface FormData {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const { login } = useContext(GlobalContext);
    const [apiError, setApiError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<FormData>();

    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit = async (data: FormData) => {
        setLoading(true);
        setApiError(null);

        try {
            // Fazendo o POST na API com email e password
            const response = await api.post("/signin", data);
            const { token, firstname, email } = response.data;

            // Criando o objeto de usuário com os dados retornados
            const user = {
                firstname,
                email,
            };

            // Salva o token e o usuário no sessionStorage e no contexto
            sessionStorage.setItem("authToken", token);
            sessionStorage.setItem("currentUser", JSON.stringify(user));

            // Chama o login no contexto
            login(user, token);

            // Redireciona o usuário
            const from = location.state?.from?.pathname || "/";
            navigate(from, { replace: true });
        } catch (err: any) {
            setApiError(
                err.response?.data?.message || "Erro ao realizar login.",
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Session background="rgba(0, 0, 0, 0.5)" backgroundimage={bgBlue}>
            <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Title>Login</Title>
                    <p>Acesso restrito para administradores</p>
                    {apiError && <ErrorMessage>{apiError}</ErrorMessage>}

                    <div>
                        <label htmlFor="email">Email</label>
                        <Input
                            type="email"
                            id="email"
                            placeholder="Informe seu email de usuário"
                            {...register("email", {
                                required: "O email é obrigatório",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Email inválido",
                                },
                            })}
                        />
                        {errors.email && (
                            <ErrorMessage>{errors.email.message}</ErrorMessage>
                        )}
                    </div>

                    <div>
                        <label htmlFor="password">Senha</label>
                        <Input
                            type="password"
                            id="password"
                            placeholder="Digite sua senha"
                            {...register("password", {
                                required: "A senha é obrigatória",
                            })}
                        />
                        {errors.password && (
                            <ErrorMessage>
                                {errors.password.message}
                            </ErrorMessage>
                        )}
                    </div>

                    <Button type="submit" disabled={loading}>
                        {loading ? "Entrando..." : "Entrar"}
                    </Button>
                </Form>
            </Container>
        </Session>
    );
};

export default Login;
