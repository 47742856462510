import styled from "styled-components";

export const LayoutContainer = styled.div`
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 100vh;
    width: 100%;
    height: 100vh;

    @media (max-width: 768px) {
        grid-template-columns: 80px 1fr;
    }

    @media (max-width: 576px) {
        grid-template-columns: 0 1fr;
    }
`;

export const Content = styled.main`
    background-color: #f9f9f9;
    padding: 1rem;
    overflow-y: auto;

    @media (max-width: 576px) {
        padding: 0.5rem;
    }
`;
